body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font: 12px sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  background: gray;
  color: white;
  margin: 20px;
  border: 0;
  border-radius: 2px;
  padding: 6px 12px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

button:hover {
  background: dimgray;
}

:focus {
  outline: none;
}

div {
  text-align: center;
  width: 100%;
  /* max-width: 800px; */
  /* border-radius: 5px; */
  overflow: hidden;
  /* margin-bottom: 20px; */
}

.vis {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}